import React from "react";
import { useParams, Link } from "react-router-dom"; // Import Link
import styled from "styled-components";
import ReactMarkdown from 'react-markdown';
import { businessAreas } from "../data/businessData"; // Import business data

// Styled Components
const PageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

const Sidebar = styled.div`
  width: 250px;
  height: 100vh;
  background: linear-gradient(90deg, #5de0e6, #004aad);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: fixed;
  left: 0;
  top: 0;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 10px;
`;

// 🔹 Company Logo in Top-Right Corner
const CompanyLogoContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 120px;
  height: auto;
  cursor: pointer; /* Add pointer cursor */
`;

const CompanyLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: white;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.2);
  }
`;

const ServicesLogo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: white;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  object-position: center;
  margin-top: auto;
  margin-bottom: 30px;
  border: 2px solid black;
  padding: 5px;
  background-clip: padding-box;
`;

// Fixed Image Box
const ImageContainer = styled.div`
  position: absolute;
  left: 180px;
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  height: 380px;
  background: white;
  border-radius: 30px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 10px;
`;

// Scrollable Image List
const ScrollableImages = styled.div`
  width: 100%;
  height: auto; /* Allow natural height */
  display: flex;
  flex-direction: row; /* Align images in a row */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scrollbar-width: thin;
  scrollbar-color: #004aad transparent;
  padding-bottom: 10px; /* Ensure space for the scrollbar */

  /* Force scrollbar to appear at the bottom */
  &::-webkit-scrollbar {
    height: 8px; /* Set scrollbar height */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #004aad;
    border-radius: 4px;
  }
`;

const ImageBox = styled.img`
  width: 300px;
  height: 380px;
  object-fit: cover;
  border-radius: 20px;
  scroll-snap-align: start;
  margin-right: 15px; /* Add space between images */
`;

// Right Side Scrollable Content
const ContentContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% - 550px);
  height: 100vh;
  overflow-y: auto;
  padding: 40px 40px;
  margin-top: 100px;
  margin-left: 600px;
  padding-left: 40px;
  padding-bottom: 400px;

  /* Customize scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #004aad;
    border-radius: 4px;
  }
`;

const ParagraphText = styled.div`
  font-size: 18px;
  color: black;
  margin-bottom: 40px;
  margin-left: 30px;
  text-align: justify;
  max-width: 900px;

  /* Style markdown elements */
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin: 1.5em 0 1em 0;
    color: #004aad;
  }

  h1:first-child {
    margin-top: 0;
  }

  h1 {
    font-size: 2em;
    border-bottom: 2px solid #004aad;
    padding-bottom: 0.5em;
    margin-top: 2em;
  }
  
  p {
    margin-bottom: 1.5em;
    text-align: justify;
    margin-left: 20px;
    line-height: 1.6;
  }

  ul, ol {
    margin-left: 2em;
    margin-bottom: 1.5em;
    line-height: 1.6;
  }

  strong {
    font-weight: bold;
    color: #004aad;
  }

  /* Add more space after last element */
  &:last-child {
    margin-bottom: 200px;
    padding-bottom: 100px;
  }
`;

const InfoPage = () => {
  const { businessId } = useParams();
  const business = businessAreas.find((b) => b.id === businessId);

  if (!business) {
    return <h1>Business Area Not Found</h1>;
  }

  return (
    <PageContainer>
      {/* Sidebar */}
      <Sidebar>
        <Title>{business.title}</Title>
        <ServicesLogo src={business.type_img} alt={business.title} />
      </Sidebar>

      {/* 🔹 Clickable Company Logo - Redirects to Home */}
      <Link to="/">
        <CompanyLogoContainer>
          <CompanyLogo src="/images/logo.png" alt="Company Logo" />
        </CompanyLogoContainer>
      </Link>

      {/* Image Box */}
      <ImageContainer>
        <ScrollableImages>
          {business.images?.map((img, index) => (
            <ImageBox key={index} src={img} alt={`${business.title} - ${index + 1}`} />
          ))}
        </ScrollableImages>
      </ImageContainer>

      {/* Scrollable Content */}
      <ContentContainer>
        {business.description?.map((text, index) => (
          <ParagraphText key={index}>
            <ReactMarkdown>{text}</ReactMarkdown>
          </ParagraphText>
        ))}
      </ContentContainer>
    </PageContainer>
  );
};

export default InfoPage;