import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import BusinessAreas from "../pages/BusinessAreas";
import Careers from "../pages/Careers";
import ContactUs from "../pages/ContactUs";
import InfoPage from "../pages/Info";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      {/* <Route path="/business-areas" element={<BusinessAreas />} /> */}
      <Route path="/careers" element={<Careers />} />
      <Route path="/contact-us" element={<ContactUs />} />

      {/* ✅ Dynamic Route for Business Areas */}
      <Route path="/info/:businessId" element={<InfoPage />} />
    </Routes>
  );
};

export default AppRoutes;