import React, { useState } from "react";
import styled from "styled-components";
import TopBar from "../components/TopBar"; // Import TopBar


// Styled Components

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 90%;
  max-width: 1200px;
  margin: 40px auto;
  gap: 30px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    width: 95%;
    margin: 20px auto;
  }
`;

const Box = styled.div`
  flex: 1;
  background: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  h3 {
    color: #004aad;
    margin-bottom: 25px;
    font-size: 24px;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #555;
  display: flex;
  align-items: center;
`;

const Asterisk = styled.span`
  color: red;
  margin-left: 5px;
`;

const Input = styled.input`
  padding: 12px 16px;
  font-size: 16px;
  border: 2px solid #e1e1e1;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:focus {
    border-color: #004aad;
    box-shadow: 0 0 0 3px rgba(0, 74, 173, 0.1);
  }
`;

const TextArea = styled.textarea`
  padding: 12px 16px;
  font-size: 16px;
  border: 2px solid #e1e1e1;
  border-radius: 8px;
  height: 120px;
  transition: all 0.3s ease;

  &:focus {
    border-color: #004aad;
    box-shadow: 0 0 0 3px rgba(0, 74, 173, 0.1);
  }
`;

const Button = styled.button`
  background: #004aad;
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 14px 28px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #003a8c;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const Message = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${({ success }) => (success ? "green" : "red")};
`;

const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .info-item {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    
    svg {
      color: #004aad;
      font-size: 20px;
    }
    
    a {
      color: #004aad;
      text-decoration: none;
      transition: color 0.3s ease;
      
      &:hover {
        color: #003a8c;
        text-decoration: underline;
      }
    }
  }
`;

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formURL = "https://formsubmit.co/ajax/om.kokate2902@gmail.com";

    try {
      const response = await fetch(formURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("✅ Message sent successfully!");
        setFormData({ name: "", phone: "", email: "", message: "" });
      } else {
        setStatus("Failed to send message. Please try again.");
      }
    } catch (error) {
      setStatus("Error sending message. Check your internet connection.");
    }
  };

  return (
    <>
      <TopBar title="CONTACT US" /> {/* Reusable TopBar */}

        <FormContainer>
          {/* Contact Form Box */}
          <Box>
            <Form onSubmit={handleSubmit}>
              <h3>Send a Message</h3>

              <Label>
                Name<Asterisk>*</Asterisk>
              </Label>
              <Input type="text" name="name" value={formData.name} onChange={handleChange} required />

              <Label>
                Phone Number<Asterisk>*</Asterisk>
              </Label>
              <Input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />

              <Label>
                Email<Asterisk>*</Asterisk>
              </Label>
              <Input type="email" name="email" value={formData.email} onChange={handleChange} required />

              <Label>
                Message<Asterisk>*</Asterisk>
              </Label>
              <TextArea name="message" value={formData.message} onChange={handleChange} required />

              <Button type="submit">Send Message</Button>
              {status && <Message success={status.includes("successfully")}>{status}</Message>}
            </Form>
          </Box>

          {/* Info Box */}
          <Box>
            <h3>Our Information</h3>
            <InfoText>
              📍 Office Address:{" "}
              <a href="https://maps.app.goo.gl/5nZDuWyjyFm1W1HaA" target="_blank" rel="noopener noreferrer">
                S.No.206/3, Office No.2, 3rd Floor, Building Oz Plaza, Viman Nagar, Pune, Maharashtra 411014
              </a>
              <br />
              📍 Manufacturing Address:{" "}
              <a href="https://maps.app.goo.gl/6gbmxtYfXbAqG7Aw7" target="_blank" rel="noopener noreferrer">
                Shri Gajanan Maharaj Nagar, Dighi, Pimpri-Chinchwad, Maharashtra 411039
              </a>
              <br />
              📞 Phone: <a href="tel:+91 20 48470310">+91 20 48470310</a>
              <br />
              📧 Email:{" "}
              <a href="mailto:info@makerstrive.com">info@makerstrive.com</a>
              <br />
              🕒 Working Hours: Mon - Fri (9AM - 6PM)
            </InfoText>
          </Box>
        </FormContainer>
    </>
  );
};

export default ContactUs;