import React, { useState } from "react";
import TopBar from "../components/TopBar";
import styled from "styled-components";

const Container = styled.div`
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    color: #004aad;
    margin-bottom: 30px;
    text-align: center;
    font-size: 28px;
    
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
`;

const Form = styled.form`
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #555;
`;

const Input = styled.input`
  padding: 12px 16px;
  font-size: 16px;
  border: 2px solid #e1e1e1;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:focus {
    border-color: #004aad;
    box-shadow: 0 0 0 3px rgba(0, 74, 173, 0.1);
  }

  &[type="file"] {
    padding: 8px;
    border: 2px dashed #e1e1e1;
    cursor: pointer;

    &:hover {
      border-color: #004aad;
    }
  }
`;

const TextArea = styled.textarea`
  padding: 12px 16px;
  font-size: 16px;
  border: 2px solid #e1e1e1;
  border-radius: 8px;
  min-height: 100px;
  transition: all 0.3s ease;

  &:focus {
    border-color: #004aad;
    box-shadow: 0 0 0 3px rgba(0, 74, 173, 0.1);
  }
`;

const SubmitButton = styled.button`
  background: #004aad;
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 14px 28px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;

  &:hover {
    background: #003a8c;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const Message = styled.p`
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  border-radius: 8px;
  font-weight: 600;
  background: ${props => props.success ? '#e6ffe6' : '#ffe6e6'};
  color: ${props => props.success ? '#006600' : '#cc0000'};
`;

const Careers = () => {
  const [formData, setFormData] = useState({});
  const [resume, setResume] = useState(null);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setResume({
          name: file.name,
          type: file.type,
          data: reader.result.split(",")[1], // Base64 format
        });
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("Submitting...");

    const formDataToSend = new URLSearchParams();
    Object.keys(formData).forEach((key) => formDataToSend.append(key, formData[key]));

    if (resume) {
      formDataToSend.append("resume", resume.data);
      formDataToSend.append("mimeType", resume.type);
      formDataToSend.append("fileName", resume.name);
    }

    try {
      const response = await fetch("https://script.google.com/macros/s/AKfycbw4clO-wC41TrT1bHDI21kaua4cbbWoUMZtNEONCm58k9HiHbsQqj5AguAp0NzFsFS2Ng/exec", {
        method: "POST",
        body: formDataToSend,
      });

      const result = await response.text();
      setMessage(result === "Success" ? "Application Submitted Successfully! ✅" : "Error submitting application.");
    } catch (error) {
      setMessage("Error submitting application.");
    }
  };

  return (
    <>
      <TopBar title="Careers" />
      <Container>
        <h2>Want To Build A Career With Makerstrive?</h2>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Name *</Label>
            <Input type="text" name="name" required onChange={handleChange} />
          </FormGroup>

          <FormGroup>
            <Label>Contact Number *</Label>
            <Input type="tel" name="contact" required onChange={handleChange} />
          </FormGroup>

          <FormGroup>
            <Label>Email *</Label>
            <Input type="email" name="email" required onChange={handleChange} />
          </FormGroup>

          <FormGroup>
            <Label>Qualification *</Label>
            <Input type="text" name="qualification" required onChange={handleChange} />
          </FormGroup>

          <FormGroup>
            <Label>Current Employer</Label>
            <Input type="text" name="employer" onChange={handleChange} />
          </FormGroup>

          <FormGroup>
            <Label>Experience In Years</Label>
            <Input type="number" name="experience" onChange={handleChange} />
          </FormGroup>

          <FormGroup>
            <Label>Role Of Current Job (Explain In A Few Words)</Label>
            <TextArea name="role" onChange={handleChange} rows="3" />
          </FormGroup>

          <FormGroup>
            <Label>Attach Your Resume *</Label>
            <Input type="file" accept=".pdf,.png,.jpg,.jpeg" required onChange={handleFileChange} />
          </FormGroup>

          <SubmitButton type="submit">Submit</SubmitButton>
        </Form>
        {message && <Message success={message.includes("Success")}>{message}</Message>}
      </Container>
    </>
  );
};

export default Careers;