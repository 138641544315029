import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import HexagonLayout from "../components/HexagonLayout";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  background: #f5f5f5;
`;

const Home = () => {
  return (
    <HomeContainer>
      <Navbar />
      <HexagonLayout />
    </HomeContainer>
  );
};

export default Home;