import { Link } from "react-router-dom";
import React, { useState } from "react";
import styled from "styled-components";

const MenuIcon = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  cursor: pointer;
  font-size: 42px;
  color: #2158a7;
  z-index: 100;
`;

const MenuList = styled.div`
  position: absolute;
  top: 60px;
  left: 20px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  z-index: 99;
  transition: 0.3s ease-in-out;

  @media (min-width: 769px) {
    background: none;
    padding: 15px 30px; /* Adds padding on desktop */
  }

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.95);
  }
`;

const MenuItem = styled.a`
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 18px;
  font-family: "Canva Sans", sans-serif;
  color: #2158a7;
  font-weight: 600;
  transition: 0.3s;
  
  &:hover {
    color: #103c76;
  }

  @media (min-width: 769px) {
    padding: 15px 40px; /* More padding on desktop */
  }

  @media (max-width: 768px) {
    font-size: 24px;
    padding: 20px;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 36px;
  cursor: pointer;
  color: #2158a7;

  @media (min-width: 769px) {
    display: none;
  }
`;

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      {/* Hamburger Menu Icon */}
      <MenuIcon onClick={() => setMenuOpen((prev) => !prev)}>☰</MenuIcon>

      {/* Menu Items */}
      <MenuList isOpen={menuOpen}>
        {/* Close Button for Mobile */}
        <CloseButton onClick={() => setMenuOpen(false)}>✖</CloseButton>

        <MenuItem as={Link} to="/about-us" onClick={() => setMenuOpen(false)}>About Us</MenuItem>
        {/* <MenuItem as={Link} to="/business-areas" onClick={() => setMenuOpen(false)}>Business Areas</MenuItem> */}
        <MenuItem as={Link} to="/careers" onClick={() => setMenuOpen(false)}>Careers</MenuItem>
        <MenuItem as={Link} to="/contact-us" onClick={() => setMenuOpen(false)}>Contact Us</MenuItem>
      </MenuList>
    </>
  );
};

export default Navbar;