export const businessAreas = [
    {
      id: "nuclear-solutions",
      title: "Nuclear Solutions",
      icon: "/svg/nuclear_solution.svg",
      type_img: "/images/Nuclear.png",
      images: [
        "/images/Nuclear/1.png",
      ],
      description: [
        "# Overview & Expertise\n" +
        "Nuclear technology plays a crucial role in energy production, medical applications, and industrial advancements. MakerStrive Engineering is at the forefront of Nuclear Solutions, specializing in the design and manufacturing of Radioactive Material Handling Equipment, Nuclear Waste Handling Equipment, and Micro SMR (1 MW) Concepts. Our expertise ensures the highest standards of safety, efficiency, and regulatory compliance in nuclear engineering.\n\n" +
        "With growing global demand for safe and sustainable nuclear solutions, our innovations support industries in managing nuclear materials responsibly, ensuring radiation containment, and optimizing energy production through small modular reactors (SMRs).\n\n" +

        "# Technologies & Products\n\n" +
        "### 1. Radioactive Material Handling Equipment\n" +
        "Handling radioactive materials requires precision, shielding, and fail-safe mechanisms to ensure safety. MakerStrive designs and fabricates customized handling equipment for nuclear facilities, ensuring controlled movement, storage, and transfer of radioactive substances.\n\n" +
        "**Key Features:**\n" +
        "• Shielded Containers & Transport Systems: Designed to prevent radiation leaks during transit\n" +
        "• Remote Handling Mechanisms: Robotic and mechanical systems to reduce human exposure\n" +
        "• Specialized Lifting & Transfer Systems: Engineered for secure material movement\n" +
        "• Compliance with Nuclear Safety Standards: Ensuring regulatory adherence for safe operations\n\n" +

        "### 2. Nuclear Waste Handling Equipment\n" +
        "Proper disposal and storage of nuclear waste are critical to maintaining environmental and human safety. Our Nuclear Waste Handling Equipment solutions are designed for containment, processing, and long-term storage of radioactive waste.\n\n" +
        "**Advantages:**\n" +
        "• High-Durability Containment Units: Leak-proof and corrosion-resistant designs for secure storage\n" +
        "• Automated Waste Segregation & Processing: Reduces manual handling, increasing safety\n" +
        "• Custom Solutions for Various Waste Types: Suitable for low, intermediate, and high-level waste\n" +
        "• Long-Term Storage Solutions: Engineered for deep geological repositories and above-ground facilities\n\n" +

        "### 3. Nuclear Power Plant Equipment\n" +
        "**Deaerator**\n" +
        "• Essential equipment for removing dissolved gases from feedwater, preventing corrosion.\n" +
        "• Enhances nuclear power plant efficiency and component lifespan.\n\n" +
        "**Helical Coil Steam Generator for Nuclear Submarines**\n" +
        "• Compact and highly efficient design.\n" +
        "• Engineered to withstand extreme operating conditions, including high pressure and vibration.\n\n" +
        "**Nuclear Spent Fuel Shearing Machine**\n" +
        "• Special Purpose Machine (SPM) designed for safe, remote-controlled processing of spent nuclear fuel bundles.\n" +
        "• Modular construction allows for remote maintenance and operational flexibility.\n\n" +

        "### 4. Micro SMR (1 MW) Concept\n" +
        "Small Modular Reactors (SMRs) represent the future of decentralized nuclear energy. MakerStrive is actively developing a 1 MW Micro SMR concept, offering compact and scalable nuclear power solutions.\n\n" +
        "**Why Micro SMR?**\n" +
        "• Compact & Scalable: Ideal for remote locations, industrial facilities, and military applications\n" +
        "• Inherent Safety Features: Passive cooling and fail-safe shutdown mechanisms\n" +
        "• Lower Capital & Operational Costs: Compared to traditional nuclear reactors\n" +
        "• Reduced Waste Generation: Higher fuel efficiency with advanced reactor design\n\n" +

        "### Compliance & Safety Standards\n" +
        "Nuclear engineering demands rigorous adherence to international safety protocols. MakerStrive ensures that all nuclear solutions comply with:\n\n" +
        "• IAEA (International Atomic Energy Agency) Guidelines\n" +
        "• ASME Section III (Nuclear Facility Components)\n" +
        "• NRC (Nuclear Regulatory Commission) Standards\n" +
        "• MIL-Specifications for Defense Applications\n\n" +

        "# Case Studies & Applications\n\n" +
        "### Case Study 1: Radioactive Waste Management for a Research Facility\n" +
        "A nuclear research facility required a customized waste handling system to manage radioactive byproducts. MakerStrive provided an automated waste containment and processing system, significantly reducing human exposure and improving compliance with safety regulations.\n\n" +

        "### Case Study 2: Secure Transport of Radioactive Materials\n" +
        "A nuclear energy provider needed shielded transport containers for moving spent fuel and other radioactive materials. MakerStrive engineered radiation-proof storage and transfer units, ensuring safe logistics and regulatory compliance.\n\n" +

        "### Applications Across Industries\n" +
        "• **Nuclear Power Plants:** Waste handling, fuel processing, and safety equipment\n\n" +
        "• **Defense & Military:** Secure handling and transport of radioactive materials\n\n" +
        "• **Medical Industry:** Shielded containers for radiopharmaceuticals\n\n" +
        "• **Research Facilities:** Controlled environments for radioactive material experiments\n\n" +

        "## Why Choose MakerStrive?\n" +
        "• **Proven Expertise in Nuclear Engineering:** Decades of experience in nuclear safety and design\n\n" +
        "• **Compliance-Driven Solutions:** Meeting and exceeding global safety standards\n\n" +
        "• **Innovative & Scalable Technologies:** From waste handling to microreactor development\n\n" +
        "• **End-to-End Support:** From concept to deployment, ensuring seamless integration\n\n" +

        "At MakerStrive, we are committed to advancing safe, efficient, and sustainable nuclear technologies. Our Nuclear Solutions empower industries with reliable and innovative equipment designed for maximum safety and performance.\n\n" +
        "For inquiries or custom solutions, reach out to our team today!"
      ],
    },
    {
      id: "precision-equipment-manufacturing",
      title: "Precision Equipment Manufacturing",
      icon: "/svg/prec_eq_mf.svg",
      type_img: "/images/manufacturing.png", 
      images: [
        "/images/Precision/1.png",
        "/images/Precision/2.jpg",
        "/images/Precision/3.jpg",
        "/images/Precision/4.jpg",
        "/images/Precision/5.jpg",
        "/images/Precision/6.png",
        "/images/Precision/7.png",
        "/images/Precision/8.jpg",
        "/images/Precision/9.jpg",
        "/images/Precision/10.jpg",
        "/images/Precision/11.png",
        "/images/Precision/12.jpg",
        "/images/Precision/13.jpg",
        "/images/Precision/14.jpg",
        "/images/Precision/15.jpg",
        "/images/Precision/16.jpg",
      ],
      description: [
        "# Overview & Expertise\n" +
        "Precision equipment plays a crucial role in industrial processes, ensuring reliability, efficiency, and compliance with industry standards. At MakerStrive Engineering, we specialize in the fabrication of Heat Exchangers, Pressure Vessels, Storage Tanks, Silos, and Pharmaceutical Equipment, delivering high-quality, customized solutions to meet the demanding needs of various industries.\n\n" +
        "With a fully equipped fabrication facility and a team of experienced engineers, we take projects from concept to completion, ensuring superior workmanship, compliance with international standards, and long-term performance.\n\n" +

        "# Capabilities & Fabrication Expertise\n\n" +
        "### 1. Heat Exchangers\n" +
        "Heat exchangers are vital for thermal management in industries such as power generation, chemical processing, and oil & gas. MakerStrive manufactures shell & tube, plate, and air-cooled heat exchangers designed for high efficiency and durability.\n\n" +
        "**Key Features:**\n" +
        "• Custom Designs: Tailored to specific heat transfer requirements\n" +
        "• High-Quality Materials: Stainless steel, carbon steel, and exotic alloys\n" +
        "• Thermal & Mechanical Performance Optimization: Using advanced simulation and FEA analysis\n" +
        "• Industry Compliance: ASME, TEMA, and PED standards\n\n" +

        "### 2. Pressure Vessels\n" +
        "MakerStrive engineers and fabricates custom pressure vessels for industrial applications, ensuring structural integrity and safety under extreme conditions.\n\n" +
        "**Why Choose Our Pressure Vessels?**\n" +
        "• High-Pressure & High-Temperature Capabilities\n" +
        "• Welded & Bolted Configurations\n" +
        "• Compliance with ASME Section VIII, Div. 1 & 2\n" +
        "• Advanced NDT & Inspection Procedures\n\n" +

        "### 3. Storage Tanks & Silos\n" +
        "Storage solutions are crucial for industries handling liquids, gases, and bulk materials. We manufacture storage tanks and silos tailored to industry-specific needs, ensuring durability and safety.\n\n" +
        "**Applications:**\n" +
        "• Oil & Gas: Crude oil, refined products, and chemical storage\n" +
        "• Agriculture & Food Processing: Grain silos, bulk material storage\n" +
        "• Pharmaceutical & Chemical: Corrosion-resistant tanks for sensitive materials\n\n" +

        "### 4. Pharmaceutical Equipment\n" +
        "The pharmaceutical industry requires high-precision equipment to meet stringent hygiene and safety regulations. MakerStrive provides custom stainless steel processing equipment, ensuring contamination-free operations.\n\n" +
        "**Our Expertise Includes:**\n" +
        "• Mixing & Blending Equipment\n" +
        "• Sterile Processing Tanks\n" +
        "• Filtration & Drying Systems\n" +
        "• GMP & FDA-Compliant Fabrication\n\n" +

        "# Materials & Quality Standards\n" +
        "Material selection and adherence to global fabrication standards are at the core of our manufacturing process. We work with a wide range of metals, including stainless steel, carbon steel, aluminum, and special alloys, ensuring corrosion resistance, high strength, and long service life.\n\n" +
        "**Certifications & Compliance:**\n" +
        "• ASME (American Society of Mechanical Engineers)\n" +
        "• TEMA (Tubular Exchanger Manufacturers Association)\n" +
        "• API (American Petroleum Institute) Standards\n" +
        "• GMP (Good Manufacturing Practice) for Pharma Equipment\n\n" +

        "# Industries & Customization\n\n" +
        "### Industries Served:\n" +
        "• **Oil & Gas:** Process equipment, heat exchangers, and pressure vessels\n" +
        "• **Power & Energy:** Thermal management solutions and industrial storage\n" +
        "• **Pharmaceutical & Food Processing:** Hygienic stainless-steel equipment\n" +
        "• **Chemical & Petrochemical:** Corrosion-resistant tanks and vessels\n\n" +

        "### Customization Capabilities:\n" +
        "• **Tailor-Made Designs:** Engineered solutions based on specific process requirements\n" +
        "• **Simulation-Driven Optimization:** FEA and CFD for enhanced performance and safety\n" +
        "• **Turnkey Manufacturing Solutions:** From design to fabrication, testing, and installation\n\n" +

        "# Case Studies & Applications\n\n" +
        "### Case Study 1: High-Pressure Vessel for a Petrochemical Plant\n" +
        "A leading petrochemical company required a high-pressure vessel for extreme operating conditions. MakerStrive designed and fabricated an ASME Section VIII, Div. 2 compliant vessel, ensuring long-term performance and safety.\n\n" +

        "### Case Study 2: Custom Heat Exchanger for a Power Plant\n" +
        "A power generation facility faced inefficiencies in their thermal management system. We provided a high-efficiency shell & tube heat exchanger, improving heat transfer rates by 20% and reducing operational costs.\n\n" +

        "### Case Study 3: Pharmaceutical Mixing Tank for Sterile Processing\n" +
        "A pharmaceutical manufacturer needed a GMP-certified stainless steel mixing tank. MakerStrive delivered a custom-built, contamination-free mixing solution, enhancing production efficiency and regulatory compliance.\n\n" +

        "## Why Choose MakerStrive?\n" +
        "• **Engineering Excellence:** Deep expertise in thermal, mechanical, and structural design\n" +
        "• **High-Quality Fabrication:** State-of-the-art manufacturing processes and materials\n" +
        "• **Compliance & Certification:** Meeting global industry standards\n" +
        "• **End-to-End Support:** From concept to delivery, ensuring seamless execution\n\n" +

        "At MakerStrive, we are dedicated to precision, performance, and reliability in industrial fabrication. Whether you need custom heat exchangers, pressure vessels, storage tanks, or pharmaceutical equipment, our expert team is ready to deliver world-class solutions."
      ],
    },
    {
      id: "engineering-simulation",
      title: "Engineering Simulation",
      icon: "/svg/engg_simulation.svg",
      type_img: "/images/manufacturing.png",
      images: [
        "/images/simulation1.jpg",
        "/images/simulation2.jpg",
        "/images/simulation3.jpg",
      ],
      description: [
        "Engineering simulation helps optimize designs before production.",
        "Reduces costs by detecting errors in early stages.",
        "Used in aerospace, automotive, and energy industries.",
      ],
    },
    {
      id: "r&d-engineering-simulation",
      title: "R&D Engineering Simulation",
      icon: "/svg/r&d.svg",
      type_img: "/images/manufacturing.png",
      images: [
        "/images/R&D/1.png",
        "/images/R&D/2.png",
        "/images/R&D/3.jpg",
      ],
      description: [
        "# Overview & Expertise\n" +
        "Research and Development (R&D) is the backbone of innovation, ensuring industries stay ahead in technological advancements. At MakerStrive Engineering, we specialize in R&D and Engineering Simulations, offering cutting-edge solutions for Defense, Navy, and Industrial Equipment Qualification. With our in-house expertise, we conduct advanced simulations, validate complex designs, and ensure compliance with MIL-grade and industry standards.\n\n" +
        "By leveraging Computational Fluid Dynamics (CFD), Finite Element Analysis (FEA), and Multiphysics Simulations, we help industries enhance performance, safety, and durability in extreme conditions.\n\n" +

        "# Core Capabilities\n\n" +
        "### 1. Military & Defense Equipment Qualification\n" +
        "Defense systems require robust, failure-resistant designs capable of withstanding extreme environments. MakerStrive specializes in MIL-grade engineering, qualifying equipment for shock, vibration, fatigue, thermal, and ballistic resistance.\n\n" +
        "**Our Expertise Covers:**\n" +
        "• Structural & Impact Analysis: Ensuring durability under combat conditions\n" +
        "• Thermal & Fluid Dynamics Simulations: Optimizing heat dissipation and flow\n" +
        "• Vibration & Fatigue Life Prediction: Extending operational life under repeated loads\n" +
        "• Ballistic & Blast-Resistance Engineering: Designing armored solutions for defense applications\n\n" +

        "### 2. Navy & Marine Simulations\n" +
        "Naval equipment operates in harsh maritime environments, requiring high corrosion resistance, hydrodynamic efficiency, and structural integrity. Our simulations help in optimizing naval structures and submarine systems.\n\n" +
        "**Key Areas of Focus:**\n" +
        "• Shock & Underwater Explosion Analysis: Ensuring survivability against blasts\n" +
        "• Hydrodynamic Simulations: Enhancing vessel performance and stability\n" +
        "• Corrosion & Material Degradation Studies: Extending lifespan in saline environments\n" +
        "• Seismic & Wave-Load Analysis: Engineering resilient offshore and naval structures\n\n" +

        "### 3. Finite Element Analysis (FEA)\n" +
        "FEA is critical in predicting structural integrity, mechanical strength, and failure risks. We use industry-leading software to analyze and refine designs before fabrication.\n\n" +
        "**FEA Capabilities:**\n" +
        "• Stress & Strain Analysis: Identifying weak points in structures\n" +
        "• Fatigue & Creep Analysis: Predicting long-term performance under cyclic loads\n" +
        "• Nonlinear & Impact Simulations: Evaluating crashworthiness and extreme force conditions\n" +
        "• Multiphysics Coupling: Simulating complex interactions like thermal-mechanical behavior\n\n" +

        "### 4. Computational Fluid Dynamics (CFD)\n" +
        "Fluid flow behavior significantly impacts system efficiency in aerospace, energy, and defense applications. Our CFD simulations provide detailed insights into flow dynamics, heat transfer, and aerodynamics.\n\n" +
        "**CFD Applications:**\n" +
        "• Aerodynamics & Drag Reduction: Optimizing designs for vehicles and UAVs\n" +
        "• Thermal Management: Enhancing cooling systems for high-performance machinery\n" +
        "• Combustion & Chemical Reactions: Improving fuel efficiency in energy systems\n" +
        "• Turbulence & Flow Optimization: Enhancing efficiency in industrial processes\n\n" +

        "# Case Studies & Applications\n\n" +
        "### Case Study 1: MIL-Standard Equipment Qualification\n" +
        "A defense contractor needed MIL-grade validation for a shock-resistant electronic enclosure. Using FEA and impact simulations, we optimized the design, ensuring compliance with MIL-STD-810G and enhancing durability.\n\n" +

        "### Case Study 2: Submarine Bulkhead Valve Analysis\n" +
        "A naval supplier required fatigue and vibration testing for bulkhead butterfly valves used in submarines. Our FEA-based analysis provided design improvements that increased the valve's lifespan by 30% under high-pressure underwater conditions.\n\n" +

        "### Case Study 3: CFD for Cooling Systems in Power Plants\n" +
        "A thermal solutions provider sought improved heat exchanger efficiency. Our CFD simulations optimized flow distribution, reducing pressure drop and increasing heat transfer efficiency by 15%.\n\n" +

        "### Industries Served\n" +
        "• **Defense & Aerospace:** MIL-spec equipment qualification, aerodynamics, and impact studies\n" +
        "• **Navy & Marine:** Hydrodynamic simulations, underwater explosions, and material resistance analysis\n" +
        "• **Energy & Power:** Heat transfer and combustion optimization for power plants\n" +
        "• **Industrial Manufacturing:** Structural and fatigue analysis for heavy machinery\n\n" +

        "## Why Choose MakerStrive?\n" +
        "• **Cutting-Edge R&D Expertise:** Combining simulation, testing, and engineering excellence\n" +
        "• **Proven Success in MIL-Grade Engineering:** Deep experience in defense and naval equipment validation\n" +
        "• **Industry-Leading Software & Tools:** Advanced FEA and CFD for high-precision analysis\n" +
        "• **End-to-End Support:** From concept validation to prototype testing and certification\n\n" +

        "At MakerStrive, we push the boundaries of engineering simulations, ensuring the highest level of performance, reliability, and compliance. Whether it's defense, marine, or industrial applications, our R&D-driven approach guarantees precision-engineered solutions tailored to the most demanding challenges."
      ],
    },
    {
      id: "new-product-development",
      title: "New Product Development",
      icon: "/svg/new_product_dev.svg",
      type_img: "/images/manufacturing.png",
      images: [
        "/images/product_dev1.jpg",
        "/images/product_dev2.jpg",
        "/images/product_dev3.jpg",
      ],
      description: [
        "New product development ensures market competitiveness.",
        "Combines user feedback, prototyping, and testing phases.",
        "Enhances customer satisfaction through continuous improvement.",
      ],
    },
    {
      "id": "renewable-solutions",
      "title": "Renewable Solutions",
      "icon": "/svg/renewable_solution.svg",
      "type_img": "/images/Renewable.png",
      "images": [
        "/images/Renewable/1.jpg",
        "/images/Renewable/2.jpg",
        "/images/Renewable/3.jpg",
        "/images/Renewable/4.jpg",
        "/images/Renewable/5.jpg",
        "/images/Renewable/6.jpg"
      ],
      "description": [
        "# Overview & Importance\n" +
        "Sustainable energy solutions are the future of industrial growth, and MakerStrive Engineering is at the forefront of this transformation. Our Renewable Solutions division focuses on providing cutting-edge technologies that enhance energy efficiency and promote eco-friendly industrial practices. We specialize in Biomass Densification Skids, Briquetting Machines, Pelletizing Machines, and Cyclone Separators, helping industries reduce waste and harness renewable resources effectively.\n\n" +
        "Biomass energy plays a crucial role in reducing dependence on fossil fuels, and our engineered solutions ensure optimal conversion of raw biomass into usable energy forms. With increasing environmental regulations and the global shift towards sustainability, our innovations are tailored to help businesses meet green energy targets while improving operational efficiency.\n\n" +
    
        "# Technologies & Products\n\n" +
    
        "### 1. Biomass Densification (Pelletizing & Briquetting Machines)\n" +
        "Biomass densification is a key technology in sustainable energy. MakerStrive designs and manufactures advanced pelletizing and briquetting machines that convert raw biomass into high-energy fuels.\n\n" +
        "**Key Features:**\n" +
        "• High durability and efficiency, ensuring minimal maintenance.\n" +
        "• Compact and automated operations for easy scalability.\n" +
        "• Compliance with global energy standards.\n\n" +
    
        "### 2. Biomass Densification Skid (Patent Applied)\n" +
        "MakerStrive has developed an innovative Biomass Densification Skid designed for efficient processing of biomass into compact fuel forms.\n\n" +
        "**Available Generations:**\n\n" +
        "• **Gen 1 – Conventional:** Proven and widely adopted technology for pelletizing and briquetting.\n\n" +
        "• **Gen 2 – Vertical:** Advanced vertical design for improved material flow, enhanced efficiency, and space optimization.\n\n" +
    
        "### 3. Biomass-to-Bio-Oil, Biochar & Gas (Fast Pyrolysis Systems)\n" +
        "Fast pyrolysis technology enables biomass conversion into valuable bio-products, including:\n\n" +
        "• **Bio-Oil:** A renewable alternative to fossil fuels, used in heating and industrial applications.\n\n" +
        "• **Biochar:** Enhances soil fertility and carbon sequestration.\n\n" +
        "• **Syngas:** Can be used for electricity generation.\n\n" +
        "• Currently in the final stages of trial testing, expected for commercial launch soon.\n\n" +
    
        "### 4. Bio-Gas to Bio-CNG Upgradation Skids\n" +
        "Our Bio-CNG upgradation skids purify biogas to achieve **98-99% methane purity**, making it an ideal alternative to conventional CNG.\n\n" +
        "**Key Features:**\n" +
        "• Advanced PSA (Pressure Swing Adsorption) technology.\n" +
        "• Reduction of carbon footprint by using waste-to-energy conversion.\n" +
        "• Seamless integration into existing gas infrastructure.\n\n" +

        "**Government Policies Supporting Biomass Adoption**\n\n" +
        "• **Ethanol Blending:** India aims for **20% blending by 2025-26**.\n\n" +
        "• **Biodiesel Blending:** **5% target by 2030**.\n\n" +
        "• **Net Zero Goals:** India plans to achieve **net-zero emissions by 2070**.\n\n" +
    
        "### 5. Cyclone Separators\n" +
        "Cyclone Separators are integral to many industrial processes, ensuring effective dust and particle separation. Our solutions are tailored for biomass and industrial applications, improving air quality and enhancing system performance.\n\n" +
    
        "# Case Studies & Applications\n\n" +
        "### Case Study 1: Biomass Briquetting for Industrial Use\n" +
        "A leading manufacturing firm sought to reduce its waste disposal costs and implement a sustainable energy solution. By integrating our Briquetting Machine, they converted biomass waste into fuel briquettes, significantly lowering their carbon footprint while achieving cost savings on energy.\n\n" +
    
        "### Case Study 2: Cyclone Separators in Biomass Processing\n" +
        "An agro-processing company faced issues with dust accumulation in their biomass conversion facility. Our Cyclone Separators were installed, improving air quality and system efficiency by 30%.\n\n" +
    
        "### Case Study 3: Solid & Liquid Waste Incinerator Plants\n" +
        "Mechanical, structural, and civil engineering.\n" +
        "Design and manufacturing of incineration systems.\n" +
        "Integration of emissions control technology for environmental compliance\n" +

        "### Case Study 4: Ground-Mounted & Rooftop Solar Systems\n" +
        "Comprehensive solar solutions with customized mounting structures.\n" +
        "Installation of high-efficiency solar panels and inverters.\n" +
        "Smart monitoring systems for real-time energy tracking.\n" +

        "### Case Study 5: Floating Solar Projects\n" +
        "Prototype installations successfully tested on water bodies.\n" +
        "Increased efficiency due to cooling effect on solar panels.\n" +
        "Integration of emissions control technology for environmental compliance\n" +

        "### Applications Across Industries\n" +
        "• **Power Plants:** Biomass briquettes and pellets as an alternative fuel\n\n" +
        "• **Agriculture:** Waste-to-energy solutions for sustainable farming\n\n" +
        "• **Manufacturing:** Industrial air filtration using cyclone separators\n\n" +
        "• **Food Processing:** Efficient biomass utilization for heat and power generation\n\n" +
    
        "## Why Choose MakerStrive?\n\n" +
        "• **Innovative Engineering:** We leverage cutting-edge technology for superior product performance\n\n" +
        "• **Sustainability Focus:** Our solutions align with global sustainability goals\n\n" +
        "• **Customization & Flexibility:** We tailor our products to meet specific industry requirements\n\n" +
        "• **Reliability & Support:** Our team provides end-to-end support, from design to implementation\n\n" +
    
        "At MakerStrive, we are committed to driving sustainable industrial transformation. Our Renewable Solutions empower businesses to embrace cleaner energy sources, optimize resources, and achieve environmental compliance without compromising efficiency.\n\n" +
        "For more details on our solutions or to discuss your specific needs, get in touch with our team today!"
      ]
    },
  ];