import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { businessAreas } from "../data/businessData"; // Import business data dynamically

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f4f4f4;
  position: relative;
`;

const HexagonContainer = styled.div`
  position: relative;
  width: clamp(260px, 25vw, 25vw);
  height: clamp(260px, 25vw, 25vw);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 750px) {
    width: clamp(45vw, 25vw, 25vw);
    height: clamp(45vw, 25vw, 25vw);
  }
`;

const HexagonImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  z-index: 10;
`;

const TriangleImage = styled.img`
  width: 85%;
  height: auto;
  position: absolute;
  object-fit: contain;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  transform: ${(props) => props.style.transform};

  &:hover {
    transform: ${(props) => props.style.transform.replace("scale(1)", "scale(1.3)")} !important;
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.3));
    z-index: 5;
  }
`;

// Define triangle positions dynamically
const positions = [
  { id: 1, style: { top: "-35%", left: "50%", transform: "translate(-50%, 0%) rotate(0deg) scale(1)" } },
  { id: 2, style: { top: "27%", left: "92%", transform: "translate(-50%, -50%) rotate(60deg) scale(1)" } },
  { id: 3, style: { top: "73%", left: "92%", transform: "translate(-50%, -50%) rotate(120deg) scale(1)" } },
  { id: 4, style: { top: "135%", left: "50%", transform: "translate(-50%, -100%) rotate(180deg) scale(1)" } },
  { id: 5, style: { top: "73%", left: "8%", transform: "translate(-50%, -50%) rotate(240deg) scale(1)" } },
  { id: 6, style: { top: "27%", left: "8%", transform: "translate(-50%, -50%) rotate(300deg) scale(1)" } },
];

const HexagonLayout = () => {
  return (
    <OuterContainer>
      <HexagonContainer>
        <HexagonImage src="/svg/polygon.svg" alt="Hexagon" />

        {businessAreas.map((business, index) => (
          <Link key={business.id} to={`/info/${business.id}`}>            <TriangleImage style={positions[index].style} src={business.icon} alt={business.title} />
          </Link>
        ))}
      </HexagonContainer>
    </OuterContainer>
  );
};

export default HexagonLayout;