import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Styled Components for TopBar
const TopBarContainer = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 1000;
  box-shadow: 0 4px 20px rgba(0, 74, 173, 0.15);
  padding: 0 40px;
  border-bottom: 1px solid rgba(93, 224, 230, 0.2);
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

const Logo = styled.img`
  width: 120px;
  height: auto;
  cursor: pointer;
  transition: all 0.3s ease;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
  }
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: bold;
  color: #004aad;
  margin-left: 20px;
  white-space: nowrap;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #5de0e6, #004aad);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }
  
  ${LogoContainer}:hover &::after {
    transform: scaleX(1);
    transform-origin: left;
  }
`;

// TopBar Component
const TopBar = ({ title }) => {
  return (
    <TopBarContainer>
      <LogoContainer>
        <Link to="/">
          <Logo src="/images/logo.png" alt="Company Logo" />
        </Link>
        <Title>{title}</Title>
      </LogoContainer>
    </TopBarContainer>
  );
};

export default TopBar;