import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import TopBar from "../components/TopBar";


// Styled Components
const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

// const TopBar = styled.div`
//   display: flex;
//   align-items: center;
//   background: linear-gradient(90deg, #5de0e6, #004aad);
//   position: sticky;
//   top: 0;
//   z-index: 100;
//   padding: 8px 12px;

//   @media (max-width: 768px) {
//     padding: 6px 10px;
//   }
// `;

// const Logo = styled.img`
//   width: 160px;
//   height: auto;
//   object-fit: contain;
//   margin-right: 8px;
//   cursor: pointer;
//   transition: transform 0.3s ease; /* Smooth transition */

//   &:hover {
//     transform: scale(1.2); /* Scale up on hover */
//   }

//   @media (max-width: 768px) {
//     width: 120px;
//   }

//   @media (max-width: 480px) {
//     width: 100px;
//   }
// `;

// const Title = styled.h2`
//   font-size: 36px;
//   font-weight: bold;
//   color: white;
//   margin: 10px 0;
//   line-height: 1.2;
//   white-space: nowrap;

//   @media (max-width: 768px) {
//     font-size: 28px;
//   }

//   @media (max-width: 480px) {
//     font-size: 22px;
//   }
// `;

const ImagesWrapper = styled.div`
  width: calc(100% - 40px);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  padding: 20px 0;
  background-color: #f8f9fa;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    gap: 8px;
  }

  @media (max-width: 768px) {
    overflow-x: auto;
    display: flex;
    gap: 8px;
    padding-bottom: 16px;
    scrollbar-width: thin;
    scrollbar-color: #004aad #f0f0f0;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #004aad;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f0f0f0;
    }
  }
`;

const ImageCard = styled.div`
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  border: ${({ active }) => (active ? "3px solid #004aad" : "2px solid transparent")};
  transition: border 0.3s ease;

  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    @media (max-width: 768px) {
      height: 80px;
    }

    @media (max-width: 480px) {
      height: 70px;
    }
  }

  p {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 13px;
  }
`;

const DescriptionsContainer = styled.div`
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 30px 0;
  background-color: #ffffff;
`;

const DescriptionCard = styled.div`
  h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #004aad;

    @media (max-width: 768px) {
      font-size: 20px;
    }

    @media (max-width: 480px) {
      font-size: 18px;
    }
  }

  p {
    font-size: 16px;
    color: #333333;
    line-height: 1.6;

    @media (max-width: 768px) {
      font-size: 15px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
`;

// Sample data
const businessAreas = [
  { id: 1, name: "Technology", img: "/images/tech.jpg", description: "Innovative technology solutions driving digital transformation." },
  { id: 2, name: "Healthcare", img: "/images/healthcare.jpg", description: "Advanced healthcare services promoting wellness." },
  { id: 3, name: "Finance", img: "/images/finance.jpg", description: "Financial services enabling growth and stability." },
  { id: 4, name: "Retail", img: "/images/retail.jpg", description: "Revolutionizing retail experiences with innovations." },
  { id: 5, name: "Energy", img: "/images/energy.jpg", description: "Sustainable energy solutions for a greener future." },
  { id: 6, name: "Education", img: "/images/education.jpg", description: "Empowering education through innovative platforms." },
];

const BusinessAreas = () => {
  const [selectedArea, setSelectedArea] = useState(businessAreas[0]);
  // const navigate = useNavigate();

  return (
    <Container>
      {/* Top Bar */}
      <TopBar title="Business Areas" />

      {/* Images Section */}
      <ImagesWrapper>
        {businessAreas.map((area) => (
          <ImageCard
            key={area.id}
            active={selectedArea.id === area.id}
            onClick={() => setSelectedArea(area)}
          >
            <img src={area.img} alt={area.name} />
            <p>{area.name}</p>
          </ImageCard>
        ))}
      </ImagesWrapper>

      {/* Description Section */}
      <DescriptionsContainer>
        <DescriptionCard>
          <h3>{selectedArea.name}</h3>
          <p>{selectedArea.description}</p>
        </DescriptionCard>
      </DescriptionsContainer>
    </Container>
  );
};

export default BusinessAreas;